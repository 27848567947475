import { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import { Div } from 'client/widget-components/basicComponents';
import React, { FC, useEffect, useRef, useState } from 'react';
import CacheImagesInView from './CacheImagesInView';
import { FadeSlot } from './FadeSlot';
import {
    slideShowCommonDefaultStyles,
    SlideShowProps,
} from './SlideShowCommon';

/**
 * gracefully handles negative numbers
 */
function realNumberModulo(number: number, divider: number): number {
    return ((number % divider) + divider) % divider;
}

function useIsFirstRender(): boolean {
    const isFirst = useRef(true);

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
}

function useUpdateEffect(effect: () => void, deps?: unknown[]) {
    const isFirst = useIsFirstRender();

    useEffect(() => {
        if (!isFirst) {
            return effect();
        }
    }, deps);
}

function getEmptyArray(length: number) {
    return new Array(length).fill(null);
}

function getIndexesInFrame(
    cursor: number,
    slotsInFrame: number,
    slideCount: number
) {
    return getEmptyArray(slotsInFrame).map((_, i) =>
        realNumberModulo(cursor + i, slideCount)
    );
}

function useIsTransitioning() {
    const [isTransitioning, _setIsTransitioning] = useState(false);
    const isTransitiongRef = useRef(false);

    function setIsTransitioning(newVal: boolean) {
        _setIsTransitioning(newVal);
        isTransitiongRef.current = newVal;
    }
    return { isTransitiongRef, isTransitioning, setIsTransitioning };
}

const SliderFadeShow: FC<SlideShowProps> = ({
    slideAnimationProps: { cursor, newEnters, slotsInFrame },
    slideProps,
    slidesData,
}) => {
    const [currentCursor, setCurrentCursor] = useState(cursor);
    const [previousCursor, setPreviousCursor] = useState(cursor);

    const { isTransitiongRef, isTransitioning, setIsTransitioning } =
        useIsTransitioning();

    useUpdateEffect(() => {
        setIsTransitioning(true);
        setCurrentCursor(cursor);
        setPreviousCursor(currentCursor);
    }, [cursor]);

    const indexesInFrame = getIndexesInFrame(
        currentCursor,
        slotsInFrame,
        slidesData.length
    );

    const previousIndexInFrame = getIndexesInFrame(
        previousCursor,
        slotsInFrame,
        slidesData.length
    );

    const slotsArray = getEmptyArray(slotsInFrame);

    return (
        <Div
            styles={[
                slideShowCommonDefaultStyles.getContainer(slotsInFrame),
                slideShowCommonDefaultStyles.filmRole,
                {
                    common: {
                        gap: '2%',
                    },
                    mobile: {
                        gap: '1.25%',
                    },
                },
            ]}
            data-auto='fade-wrapper'
        >
            {slotsArray.map((_, slotIndex) => {
                const currentSlideIndex = indexesInFrame[slotIndex];
                const previousSlideIndex = previousIndexInFrame[slotIndex];
                const shouldAnimateContent =
                    (newEnters > 0 && slotIndex < newEnters) ||
                    (newEnters < 0 &&
                        slotIndex - (slotsInFrame - 1) > newEnters);

                return (
                    <FadeSlot
                        currentSlideData={slidesData[currentSlideIndex]}
                        key={slotIndex}
                        previousSlideData={slidesData[previousSlideIndex]}
                        shouldAnimateContent={shouldAnimateContent}
                        onAnimationEnd={() => {
                            if (isTransitiongRef.current) {
                                setIsTransitioning(false);
                            }
                        }}
                        slideProps={slideProps}
                        isTransitioning={isTransitioning}
                        styles={[
                            { common: { flex: '1' } },
                            getSlotStyles(slotIndex, slotsInFrame),
                        ]}
                    />
                );
            })}
            <CacheImagesInView slidesData={slidesData} />
        </Div>
    );
};

function getSlotStyles(
    slotIndex: number,
    desktopSlots: number
): ResponsiveStylesStructured {
    const mobileSlots = desktopSlots > 2 ? 3 : 1;
    return slotIndex < mobileSlots
        ? {}
        : {
              mobile: {
                  display: 'none',
              },
          };
}

export default SliderFadeShow;
