// FIXME - a better impl would be to inject as a prop instead of globalThis usage
export default () => {
    const insideEditor = () => globalThis.$?.DM?.insideEditor?.();
    const isInPreview = () => {
        const env = globalThis.dmAPI?.getCurrentEnvironment?.();
        return env === 'preview' || env === 'editor';
        // the widget is rendered once both for editor and preview
        // so there is no way to apply changes to preview but not to editor
    };

    return { insideEditor, isInPreview };
};
