import React, { useRef } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ButtonStyles } from 'client/widget-components/CommonModelTypes';
import Button from 'client/widget-components/components/Button';
import Text, {
    TextDomTag,
    getDomTagByValue,
} from 'client/widget-components/basicComponents/Text';
import { Div, Li, Span } from 'client/widget-components/basicComponents';
import {
    ResponsiveStylesWide,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { AccordionItemData, AccordionStyles } from '../accordionCommonTypes';

import { AccordionItemArrow } from './AccordionItemArrow';
import { Media } from 'client/widget-components/components/Media';

export interface AccordionItemProps extends AccordionItemData {
    styles?: AccordionStyles;
    arrowDirection?: string;
    arrowType?: string;
    hasTitleIcons?: boolean;
    onItemToggle?: Function;
    titleTag?: string;
    index: number;
    disableTransition: boolean;
}

const AccordionItem: React.FC<AccordionItemProps> = (props) => {
    const {
        onItemToggle,
        index,
        isOpen,
        styles,
        title,
        titleTag = 'h3',
        titleIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"> <path fill="inherit" d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"></path></svg>',
        desc,
        button,
        media,
        linkDomAttributes,
        showButton,
        arrowDirection,
        arrowType,
        hasTitleIcons,
        disableTransition,
    } = props;

    const contentRef = useRef<HTMLDivElement>(null);

    const titleClicked = () => {
        onItemToggle?.(index);
    };

    const titleDomTag = getDomTagByValue(titleTag || 'h3');

    const mediaContainer = (
        <Div
            data-grab='accordion-item-media-container'
            styles={[mediaContainerDefaultStyles, styles?.mediaContainer]}
        >
            {media && (
                <Media
                    styles={[mediaDefaultStyles, styles?.media]}
                    dataGrab='accordion-item-media'
                    {...media}
                />
            )}
        </Div>
    );

    let expandedStyles = {};
    if (contentRef.current) {
        expandedStyles = getAccordionItemDescExpandedStyles(
            disableTransition ? 300000 : contentRef.current.scrollHeight
        );
    }

    return (
        <Li
            styles={[accordionItemDefaultStyles, styles?.itemContainer]}
            data-grab='accordion-item-container'
        >
            <Div
                styles={[
                    accordionItemTitleWrapperDefaultStyles,
                    arrowDirection === 'left' &&
                        accordionItemTitleWrapperLeftArrowStyles,
                    styles?.itemTitleWrapper,
                ]}
                onClick={titleClicked}
                data-grab='accordion-item-title-wrapper'
            >
                {title && (
                    <Text
                        grabId='accordion-item-title'
                        styles={[
                            accordionItemTitleDefaultStyles,
                            styles?.itemTitle,
                        ]}
                        tag={titleDomTag}
                    >
                        {hasTitleIcons ? (
                            <Span
                                styles={[
                                    accordionItemTitleIconDefaultStyles,
                                    styles?.itemTitleIcon,
                                ]}
                                data-grab='accordion-item-title-icon'
                                dangerouslySetInnerHTML={{
                                    __html: titleIcon,
                                }}
                            ></Span>
                        ) : null}
                        <Span styles={accordionItemTitleTextStyles}>
                            {title}
                        </Span>
                    </Text>
                )}
                <AccordionItemArrow
                    styles={styles}
                    isOpen={isOpen}
                    arrowType={arrowType}
                />
            </Div>
            <Div>
                <Div
                    ref={contentRef}
                    styles={[
                        accordionItemDescDefaultStyles,
                        isOpen ? expandedStyles : {},
                    ]}
                >
                    <Div
                        styles={[
                            accordionItemDescInnerDefaultStyles,
                            styles?.itemDesc,
                        ]}
                        data-grab='accordion-item-desc'
                    >
                        {media && mediaContainer}
                        <Div styles={[accordionItemDescAndButtonDefaultStyles]}>
                            {desc && (
                                <Text
                                    grabId='accordion-item-desc-text'
                                    data-auto='desc'
                                    styles={[
                                        nestResponsiveStyles(
                                            descOverrideStyles,
                                            {
                                                innerSelector: 'p',
                                            }
                                        ),
                                        styles?.desc,
                                    ]}
                                    tag={TextDomTag.div}
                                    domAttrs={{
                                        dangerouslySetInnerHTML: {
                                            __html: desc,
                                        },
                                    }}
                                />
                            )}

                            {showButton && button && (
                                <Button
                                    styles={{
                                        ...(styles?.button ??
                                            ({} as ButtonStyles)),
                                        root: [
                                            buttonContainerDefaultStyles,
                                            styles?.button?.root,
                                        ],
                                    }}
                                    linkFunctionalityDomAttributes={
                                        linkDomAttributes
                                    }
                                    buttonContent={button}
                                />
                            )}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Li>
    );
};

const accordionItemDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        cursor: 'pointer',
    },
};

const accordionItemTitleIconDefaultStyles: ResponsiveStylesWide = {
    [RuntimeMediaQuery.COMMON]: {
        width: '20px',
        aspectRatio: '1 / 1',
        display: 'flex',
        '& svg': {
            fill: 'currentColor',
        },
    },
};
const accordionItemTitleWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        paddingTop: 16,
        paddingBottom: 16,
    },
};
const accordionItemTitleWrapperLeftArrowStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        gap: '12px',
    },
};
const accordionItemTitleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        flex: 1,
        textAlign: 'start',
    },
};

const accordionItemTitleTextStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        flex: 1,
    },
};

const accordionItemDescDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-out',
        height: 'auto',
        maxHeight: 0,
    },
};

function getAccordionItemDescExpandedStyles(maxHeight: number) {
    return {
        [RuntimeMediaQuery.COMMON]: {
            maxHeight: maxHeight,
        },
    };
}

const accordionItemDescInnerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        padding: '16px',
        paddingTop: 16,
        paddingBottom: 16,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '24px',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    },
    [RuntimeMediaQuery.MOBILE]: {
        flexDirection: 'column',
    },
};
const accordionItemDescAndButtonDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        textAlign: 'start',
        flex: 1,
    },
};

const mediaContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {},
};

const mediaDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: 123,
        borderRadius: 0,
        borderStyle: 'solid',
        maxWidth: 'unset',
    },
    [RuntimeMediaQuery.MOBILE]: {
        maxWidth: '100%',
    },
};

const descOverrideStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        marginBlock: 0,
    },
};

const buttonContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: 200,
        paddingInlineStart: 20,
        paddingInlineEnd: 20,
        margin: 0,
    },
};

export default AccordionItem;
