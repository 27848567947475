import { WidgetSchema } from 'types';
import {
    hydrateComponent,
    renderObserverComponent,
} from './infra/renderers/clientRenderers';
import { shouldHydrateWidgetOnRuntime } from './infra/renderers/utils';
import { getWrapperId } from 'client/infra/renderers/utils';
import { logger } from '@shared-services/log-service';

export const hydrate = (
    widget: WidgetSchema,
    {
        observer = false,
        markupId = widget.id,
        asyncHydration = !!window.requestIdleCallback,
    }: { observer?: boolean; markupId?: string; asyncHydration?: boolean } = {}
): void => {
    const callback = () => {
        hydrateComponent({ ...widget, observer }, { markupId });
    };
    if (asyncHydration) {
        requestIdleCallback(callback);
    } else {
        callback();
    }
};

export const hydrateObserverWidget = (widget: WidgetSchema): void => {
    hydrate(widget, { observer: true });
};

export const hydrateAll = (widgets: WidgetSchema[] = []) => {
    widgets.forEach((widget) => hydrate(widget));
};

export const hydrateAllObserverWidgets = (widgets: WidgetSchema[] = []) => {
    widgets.forEach((data) => hydrateObserverWidget(data));
};

export const renderObserver = ({
    id,
    type,
    props,
    model,
}: WidgetSchema): void => {
    renderObserverComponent({ id, type, props, model, observer: true });
};

export const shouldHydrateOnRuntime = (widgets: WidgetSchema[]): boolean => {
    return widgets.some((widget) => shouldHydrateWidgetOnRuntime(widget));
};

export function initiateWidget(widgetJson: WidgetSchema, observer: boolean) {
    let initialized: boolean;
    const widgetId = widgetJson.id;
    const ssrWrapper = document.getElementById(getWrapperId(widgetId));
    if (!ssrWrapper) {
        const msg = 'ssr initiate widget - element not found';
        logger.warn({
            msg,
            tags: ['__new-runtime__'],
            widgetId,
        });
        throw new Error(msg);
    }

    const widgetObserver = new IntersectionObserver((entries) => {
        if (initialized) {
            return;
        }
        const intersectingEntry = entries.some((en) => en.isIntersecting);

        if (intersectingEntry) {
            console.debug(`ssr initializing widget: ${widgetId}`);
            hydrate(widgetJson, {
                observer,
            });
            initialized = true;
        }
    });

    widgetObserver.observe(ssrWrapper);
}
