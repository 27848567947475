import React from 'react';
import { RuntimeWidget } from 'client/types';
import { FilterSortExtensionData } from './components/utils/types';
import FilterSortMenu from './components/FilterSortMenu';
import { Div } from 'client/widget-components/basicComponents';
import TriggerButton from './components/TriggerButton';
import { FilterSortContext } from './components/utils/filterSortContext';
import { WidgetStyles } from './components/utils/types/styles';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import useFilterSortWidget from './useFilterSortWidget';
import withErrorBoundary from '../../widget-components/components/ErrorBoundary/withErrorBoundary';

export interface RuntimeFilterSortProps extends FilterSortExtensionData {
    widgetId: string;
    showButtonHoverEffect?: boolean;
    _styles?: WidgetStyles;
}

export const RuntimeFilterSort: RuntimeWidget<RuntimeFilterSortProps> = (
    props: RuntimeFilterSortProps
) => {
    const {
        widgetId,
        menuPanelMode,
        premiumWidget,
        currentPageType,
        _styles = {},
    } = props;
    const {
        filterSortLogic,
        onBtnClick,
        showWarning,
        setIsMenuOpen,
        shouldRenderMenu,
    } = useFilterSortWidget(props);

    const widgetContext = {
        styles: _styles,
        menuPanelMode,
        inDesignMode: menuPanelMode === 'DESIGN',
        hasProducts: props.hasProducts,
        isPremiumWidget: premiumWidget,
        currentPageType,
    };
    return (
        <FilterSortContext.Provider value={widgetContext}>
            <Div styles={wrapperDefaultStyles}>
                <TriggerButton
                    onClick={onBtnClick}
                    showWarning={showWarning}
                    selectedCount={filterSortLogic?.selectedCount}
                    text={props.buttonText}
                    styleWrapperSelector={`filter-sort-trigger-${widgetId}`}
                    showButtonHoverEffect={props.showButtonHoverEffect}
                />
            </Div>
            {shouldRenderMenu && filterSortLogic && (
                <FilterSortMenu
                    filterSortLogic={filterSortLogic}
                    props={{
                        ...props,
                        styleWrapperSelector: `filter-sort-menu-${widgetId}`,
                        setIsMenuOpen,
                    }}
                />
            )}
        </FilterSortContext.Provider>
    );
};

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        minWidth: 'fit-content',
    },
};

export default withErrorBoundary({
    Comp: RuntimeFilterSort,
    logProps: true,
    componentName: 'FilterSort',
});
