import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ecomObserver } from '@shared-services/ecom-service';
import { localizationService } from '@shared-services/localization-service';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div, Span } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { ProductType } from 'client/widget-components/ecom/stores';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';

export interface RuntimeProductPriceProps {
    productData: ProductType;
    productState: ProductState;
    _styles?: {
        price: ResponsiveStylesProp;
        compareAtPrice: ResponsiveStylesProp;
        frequency: ResponsiveStylesProp;
        frame: ResponsiveStylesProp;
    };
}

const RuntimeProductPrice: FC<RuntimeProductPriceProps> = ({
    _styles: styles,
    productData,
    productState,
}) => {
    if (!productData) {
        return null;
    }

    const frequency = productState.selectedPaymentPlan?.frequency;

    return (
        <Div
            data-auto='product-price-widget'
            data-grab='product-price-frame'
            styles={styles?.frame}
        >
            <Text
                tag={TextDomTag.h4}
                data-grab='product-compare-at-price'
                styles={[
                    productData.displayed_compare_at
                        ? compareAtPriceWrapperDefaultStyles
                        : compareAtPriceWrapperHiddenStyles,
                    styles?.compareAtPrice,
                ]}
            >
                <Span styles={compareAtPriceDefaultStyles}>
                    {productData.displayed_compare_at}
                </Span>
            </Text>

            <Text
                tag={TextDomTag.h4}
                data-grab='product-price'
                styles={[priceDefaultStyles, styles?.price]}
            >
                {productData.displayed_price}
            </Text>

            <Text
                tag={TextDomTag.h4}
                data-grab='product-frequency'
                styles={[frequencyDefaultStyles, styles?.frequency]}
            >
                {frequency && (
                    <>
                        /
                        {localizationService.str(
                            `ui.runtimessr.productPriceOptions.frequency.${frequency.toLowerCase()}`
                        )}
                    </>
                )}
            </Text>
        </Div>
    );
};

const compareAtPriceWrapperHiddenStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
    },
};

const compareAtPriceWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'inline',
        paddingRight: 8,
        margin: 0,
    },
};

const compareAtPriceDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textDecoration: 'line-through',
    },
};

const priceDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'inline',
        margin: 0,
    },
};

const frequencyDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'inline',
        margin: 0,
    },
};

const RuntimeProductPriceWidget = withErrorBoundary({
    Comp: ecomObserver(RuntimeProductPrice),
    componentName: 'RuntimeProductPrice',
});

export default RuntimeProductPriceWidget;
