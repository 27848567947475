import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { localizationService } from '@shared-services/localization-service';
import { ProductPaymentPlan } from 'client/widget-components/ecom/stores/types';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { Div } from 'client/widget-components/basicComponents';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from 'client/widget-components/ecom/consts';
import { PriceOptionsStyles } from './productPriceOptionsTypes';

interface PriceOptionLabelProps {
    option: ProductPaymentPlan;
    styles?: PriceOptionsStyles;
}

const { str } = localizationService;

export const ProductPriceOptionsItem: FC<PriceOptionLabelProps> = ({
    option,
    styles,
}) => {
    const {
        id,
        name,
        tag_line,
        frequency,
        expiration_count,
        interval,
        plan_displayed_price,
    } = option;
    const expirationInterval =
        expiration_count && interval && expiration_count * interval;

    return (
        <>
            <Text
                tag={TextDomTag.h4}
                data-grab='price-name'
                styles={[nameDefaultStyles, styles?.name]}
            >
                {name}
            </Text>

            {tag_line && (
                <Text
                    tag={TextDomTag.h6}
                    data-grab='price-tagLine'
                    styles={[tagLineDefaultStyles, styles?.tagLine]}
                >
                    {tag_line}
                </Text>
            )}

            <Div>
                <Text
                    tag={TextDomTag.h4}
                    data-grab='price-and-frequency'
                    styles={[
                        priceAndFrequencyDefaultStyles,
                        styles?.priceAndFrequency,
                    ]}
                >
                    {plan_displayed_price}
                    {frequency && (
                        <>
                            {' '}
                            /{' '}
                            {interval && interval > 1 ? (
                                <>
                                    {interval}{' '}
                                    {str(
                                        `ui.runtimessr.productPriceOptions.frequencyPlural.${frequency.toLowerCase()}`
                                    )}
                                </>
                            ) : (
                                <>
                                    {str(
                                        `ui.runtimessr.productPriceOptions.frequency.${frequency.toLowerCase()}`
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Text>

                {id !== PRODUCT_ONE_TIME_PAYMENT_PLAN_ID && (
                    <Text
                        tag={TextDomTag.h6}
                        data-grab='expiration'
                        styles={[expirationDefaultStyles, styles?.expiration]}
                    >
                        {expirationInterval && frequency ? (
                            <>
                                {str(
                                    'ui.runtimessr.productPriceOptions.expiresAfter'
                                )}{' '}
                                {expirationInterval}{' '}
                                {str(
                                    `ui.runtimessr.productPriceOptions.frequency${
                                        expirationInterval > 1 ? 'Plural' : ''
                                    }.${frequency.toLowerCase()}`
                                )}
                            </>
                        ) : (
                            str('ui.runtimessr.productPriceOptions.autoRenew')
                        )}
                    </Text>
                )}
            </Div>
        </>
    );
};

const nameDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        paddingBlockEnd: 4,
        margin: 0,
    },
};

const tagLineDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        paddingBlockEnd: 8,
        margin: 0,
    },
};

const priceAndFrequencyDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        paddingInlineEnd: 8,
        margin: 0,
        display: 'inline',
    },
};

const expirationDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        display: 'inline',
    },
};
