import { CSSProperties } from 'react';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';
import { OptionType } from 'client/widget-components/ecom/stores';
import { ResponsiveStylesStructured } from '@duda-co/responsive-styles';

type OldWidgetStyles = {
    wrapper?: CSSProperties;
    fields?: CSSProperties;
    title?: CSSProperties;
};

export type OV_WidgetStyles = {
    wrapper?: ResponsiveStylesStructured;
    title?: ResponsiveStylesStructured;
    fields?: ResponsiveStylesStructured;
};

export interface RuntimeOptionsVariationsProps {
    productState: ProductState;
    _styles?: OldWidgetStyles | OV_WidgetStyles;
    updateProduct: (optionKey: string, optionValue: OptionType) => void;
    dropdownPlaceholder?: string;
    editorEmptyMessage?: string;
    renderType?: OptionsRenderType;
}

export enum OptionsRenderType {
    DROPDOWN = 'DROPDOWN',
    RADIOBUTTON = 'RADIOBUTTON',
}
