import React, { FC } from 'react';
import { Div, Svg } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

interface Props {
    styles?: {
        itemArrowWrapper?: ResponsiveStylesProp;
        itemArrow?: ResponsiveStylesProp;
        itemArrowSvg?: ResponsiveStylesProp;
    };
    isOpen?: boolean;
    arrowType?: string;
}

/**
 * TODO: organize lazy optimization strategy code.
 * Widget should properly know if it is aboveFold or not, and put this in a React context.
 * Widget can also decide on the optimizationStrategy based on viewport etc, but it will be determined by default by the React.context.
 */
export const AccordionItemArrow: FC<Props> = ({
    styles,
    isOpen,
    arrowType,
}) => {
    const getArrowByType = (type?: string) => {
        switch (type) {
            case 'arrow':
            default:
                return (
                    <Svg
                        styles={[
                            accordionItemArrowSVGDefaultStyles,
                            styles?.itemArrowSvg,
                            isOpen ? accordionItemArrowSVGOpenStyles : {},
                        ]}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 1152 1792'
                        fill='currentColor'
                    >
                        <path
                            fill='inherit'
                            d='M1075 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'
                        ></path>
                    </Svg>
                );
            case 'leftArrow':
                return (
                    <Svg
                        styles={[
                            accordionItemLeftArrowSVGDefaultStyles,
                            styles?.itemArrowSvg,
                            isOpen ? accordionItemLeftArrowSVGOpenStyles : {},
                        ]}
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        viewBox='0 0 640 1792'
                    >
                        <path
                            fill='inherit'
                            d='M595 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'
                        ></path>
                    </Svg>
                );
            case 'plus':
                return (
                    <Div>
                        <Div
                            styles={[
                                accordionItemArrowVerticalDefaultStyles,
                                isOpen && accordionItemArrowVerticalOpenStyles,
                            ]}
                        />
                        <Div
                            styles={[
                                accordionItemArrowHorizontalDefaultStyles,
                                isOpen
                                    ? accordionItemArrowHorizontalOpenStyles
                                    : {},
                            ]}
                        />
                    </Div>
                );
        }
    };

    return (
        <Div
            styles={[
                accordionItemArrowWrapperDefaultStyles,
                styles?.itemArrowWrapper,
            ]}
            data-grab='accordion-item-arrow-wrapper'
        >
            <Div
                styles={[accordionItemArrowDefaultStyles, styles?.itemArrow]}
                data-grab='accordion-item-arrow'
            >
                {getArrowByType(arrowType)}
            </Div>
        </Div>
    );
};

const accordionItemArrowWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        aspectRatio: '1 / 1',
        width: 20,
    },
};
const accordionItemArrowDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        transition: 'transform 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
};
const accordionItemArrowSVGDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transition: 'all 0.5s ease-in-out',
        fill: 'currentColor',
        height: '100%',
        width: '100%',
    },
};
const accordionItemArrowSVGOpenStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transform: 'rotate(180deg)',
    },
};
const accordionItemLeftArrowSVGDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transition: 'all 0.5s ease-in-out',
        fill: 'currentColor',
        height: '100%',
        width: '100%',
    },
};
const accordionItemLeftArrowSVGOpenStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transform: 'rotate(90deg)',
    },
};
const accordionItemArrowVerticalDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transition: 'all 0.5s ease-in-out',
        transform: 'rotate(-90deg)',
        position: 'absolute',
        backgroundColor: 'currentColor',
        width: '10%',
        height: '100%',
        left: ' 50%',
        marginLeft: '-5%',
        top: '50%',
        marginTop: '-50%',
    },
};
const accordionItemArrowHorizontalDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transition: 'all 0.5s ease-in-out',
        transform: 'rotate(-90deg)',
        backgroundColor: 'currentColor',
        position: 'absolute',
        width: '100%',
        height: '10%',
        left: '50%',
        marginLeft: '-50%',
        top: '50%',
        marginTop: '-5%',
        opacity: '1',
    },
};
const accordionItemArrowVerticalOpenStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transform: 'rotate(90deg)',
    },
};
const accordionItemArrowHorizontalOpenStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        transform: 'rotate(90deg)',
        opacity: 0,
    },
};
