import React, { memo } from 'react';
import { SortableField, SortIDS } from '../utils/types';
import { RadioButton } from 'client/widget-components';
import MenuItem from '../MenuItem';
import {
    useFilterSortContext,
    useFilterSortStyles,
} from '../utils/filterSortContext';
import { inputsSelectors } from '../utils';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

const radioBtnDataGrabs = {
    labelDataGrab: inputsSelectors.inputLabel,
    outerCircleDataGrab: inputsSelectors.input,
    innerCircleDataGrab: inputsSelectors.inputIcon,
};
interface SortItemsListProps {
    name: string;
    values: SortableField[];
    onChange: (selectedSort: SortableField) => void;
    currentSelection: Omit<SortableField, 'fieldDisplayName'>;
}
const hiddenPremiumSortItems: Array<string> = [SortIDS.NEWEST, SortIDS.PRICE];
function SortItemsList({
    name,
    values,
    currentSelection,
    onChange,
}: SortItemsListProps) {
    const { inDesignMode, isPremiumWidget } = useFilterSortContext();
    const { inputsStyle } = useFilterSortStyles();

    return (
        <MenuItem title={name} dataAuto='menu-item-sort'>
            {values.map((sortField) => {
                if (
                    !isPremiumWidget &&
                    hiddenPremiumSortItems.includes(sortField.fieldId)
                ) {
                    return null;
                }
                if (sortField.disabled && !inDesignMode) {
                    return null;
                }
                const sortKey = `${sortField.fieldId}_${sortField.sortDirection}`;
                const selectedValue = `${currentSelection.fieldId}_${currentSelection.sortDirection}`;
                return (
                    <RadioButton
                        key={sortKey}
                        value={sortKey}
                        selectedValue={selectedValue}
                        label={sortField.fieldDisplayName}
                        onChange={() => onChange(sortField)}
                        labelWrapperStyles={[
                            labelWrapperDefaultStyles,
                            inputsStyle?.inputLabel,
                        ]}
                        labelStyles={inputsStyle?.inputLabel}
                        inputStyles={inputsStyle?.input}
                        inputIconStyles={inputsStyle?.inputIcon}
                        data-auto={sortKey}
                        dataGrabs={radioBtnDataGrabs}
                    />
                );
            })}
        </MenuItem>
    );
}

const labelWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '31px',
    },
};
export default memo(SortItemsList);
