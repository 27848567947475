import React from 'react';
import withErrorBoundary from '../ErrorBoundary/withErrorBoundary';
import Arrows, { ArrowPaginationProps } from './Arrows/Arrows';
import Bullets from './Bullets/Bullets';
import Thumbs, { ThumbsPaginationProps } from './Thumbs/Thumbs';

export enum PaginationType {
    BULLETS = 'bullets',
    THUMBS = 'thumbs',
    ARROWS = 'arrows',
}

export type PaginationProps = ThumbsPaginationProps &
    ArrowPaginationProps & {
        type?: PaginationType;
    };

const Pagination: React.FC<PaginationProps> = (props) => {
    switch (props.type) {
        case PaginationType.ARROWS: {
            return <Arrows {...props} />;
        }
        case PaginationType.THUMBS: {
            return <Thumbs {...props} />;
        }
        default: {
            return <Bullets {...props} />;
        }
    }
};

export default withErrorBoundary({
    Comp: Pagination,
    logProps: true,
    componentName: 'Pagination',
});
