import { LocalizationService } from '../types/localization-service';
import { localization } from '@duda-co/host-services';

export const localizationService: LocalizationService = {
    str: (key) => {
        return localization.str(key);
    },
    getAllStringsForCurrentLang: () => {
        throw new Error('not implemented');
    },
};
