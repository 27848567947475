export interface OptionType {
    label?: string;
    value: string | number;
    disabled?: boolean;
}

export interface OptionsVariationsType {
    [option: string]: Array<OptionType>;
}

export interface ProductType {
    identifier: string;
    sku: string;
    price: number;
    displayed_price: string;
    displayed_compare_at?: string;
    images: Image[];
    name: string;
    description: string;
    options: OptionsVariationsType;
    variations: VariationType[];
    image: string;
    additional_image: string;
    link_text: string;
    seo_title: string;
    seo_description: string;
    internal_quantity?: number;
    external_id?: string;
    shippable?: boolean;
    purchase_options?: ProductPurchaseOptionType[];
    payment_plans?: ProductPaymentPlan[];
}

export enum ProductPurchaseOptionType {
    ONE_TIME = 'ONE_TIME',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface ProductPaymentPlan {
    id: string;
    name: string;
    tag_line?: string;
    discount_percentage?: number;
    frequency?: ProductPaymentFrequency;
    interval?: number;
    expiration_count?: number;
    plan_price: number;
    plan_displayed_price: string;
}

export enum ProductPaymentFrequency {
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export interface VariationType
    extends Pick<
        ProductType,
        | 'identifier'
        | 'price'
        | 'displayed_price'
        | 'images'
        | 'sku'
        | 'internal_quantity'
        | 'external_id'
    > {
    selected_options_values: string;
    plans_prices: Array<{
        id: string;
        price: number;
        displayed_price: string;
    }>;
}

// Merges product & current variation data
export type ProductEntity = ProductType &
    Partial<VariationType> & { itemId: string; productId: string };

interface Image {
    alt_text: string;
    image: string;
}
