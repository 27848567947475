import React from 'react';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { SlideData } from '../sliderCommonTypes';
import { Placeholder } from 'client/widget-components/components/Placeholder';
import SlideContainer from './SlideContainer';
import { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import { ButtonStyles } from 'client/widget-components/CommonModelTypes';
import Button from 'client/widget-components/components/Button';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { Div } from 'client/widget-components/basicComponents';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Media } from 'client/widget-components/components/Media';

export interface SlideContentPropsBase extends SlideData {
    imageLayout?: ImageLayout;
    showButtonHoverEffect?: boolean;
    styles?: {
        container?: ResponsiveStylesProp;
        contentContainer?: ResponsiveStylesProp;
        title?: ResponsiveStylesProp;
        desc?: ResponsiveStylesProp;
        button?: ButtonStyles;
        mediaContainer?: ResponsiveStylesProp;
        media?: ResponsiveStylesProp;
        overlay?: ResponsiveStylesProp;
    };
}

export interface SlideContentProps extends SlideContentPropsBase {
    contentAnimationTypeCssClass?: string;
    contentAnimationMode?: 'off' | 'anim-idle' | 'anim-active';
    onContentAnimationCompleted?: () => void;
    outOFViewPort?: boolean;
}

export enum ImageLayout {
    AS_ELEMENT = 'AS_ELEMENT',
    BACKGROUND = 'BACKGROUND',
    AS_CONTENT_ELEMENT = 'AS_CONTENT_ELEMENT',
}

const SlideContent: React.FC<SlideContentProps> = (props) => {
    const {
        styles,
        imageLayout = ImageLayout.BACKGROUND,
        uuid,
        title,
        desc,
        button,
        media,
        linkDomAttributes,
        showButton,
        contentAnimationTypeCssClass,
        contentAnimationMode: _contentAnimationMode,
        imgCssObjectPositionValue,
        showButtonHoverEffect,
        onContentAnimationCompleted,
        outOFViewPort,
    } = props;

    const contentAnimationMode =
        !contentAnimationTypeCssClass || contentAnimationTypeCssClass === 'none'
            ? 'off'
            : _contentAnimationMode ?? 'off';

    if (!(media || title || desc || linkDomAttributes)) {
        return <Placeholder />;
    }

    const mediaContainer = (
        <Div
            data-grab='slide-media-container'
            styles={[
                defaultStyles.mediaContainer,
                imageLayout === ImageLayout.BACKGROUND &&
                    defaultStyles.mediaContainerBg,
                styles?.mediaContainer,
            ]}
        >
            {media && (
                <>
                    <Media
                        renderPlaceholderOnServer={outOFViewPort}
                        dataGrab='slide-media'
                        styles={[
                            defaultStyles.media,
                            imageLayout === ImageLayout.BACKGROUND &&
                                defaultStyles.imageBg,
                            {
                                common: {
                                    objectPosition: imgCssObjectPositionValue,
                                },
                            },
                            styles?.media,
                        ]}
                        {...media}
                    />
                    <Div
                        data-grab='slide-overlay'
                        styles={[defaultStyles.imgOverlay, styles?.overlay]}
                    />
                </>
            )}
        </Div>
    );

    return (
        <SlideContainer
            styles={styles?.container}
            linkFunctionalityDomAttributes={
                !showButton && props.linkDomAttributes
            }
            data-auto={`ssr-slide-${uuid}`}
        >
            {imageLayout !== ImageLayout.AS_CONTENT_ELEMENT && mediaContainer}
            <Div
                className={
                    contentAnimationMode === 'anim-active'
                        ? ['animated', contentAnimationTypeCssClass].join(' ')
                        : ''
                }
                data-grab='slideContentContainer'
                styles={[
                    defaultStyles.contentContainer,
                    styles?.contentContainer,
                    {
                        common: {
                            visibility:
                                contentAnimationMode === 'anim-idle'
                                    ? 'hidden'
                                    : 'visible',
                        },
                    },
                ]}
                onAnimationEnd={onContentAnimationCompleted}
            >
                {imageLayout === ImageLayout.AS_CONTENT_ELEMENT &&
                    mediaContainer}

                {title && (
                    <Text
                        grabId='title'
                        styles={[defaultStyles.title, styles?.title]}
                        tag={TextDomTag.h3}
                    >
                        {title}
                    </Text>
                )}
                {desc && (
                    <Text
                        grabId='description'
                        data-auto='desc'
                        styles={[
                            nestResponsiveStyles(defaultStyles.descOverrides, {
                                innerSelector: 'p',
                            }),
                            styles?.desc,
                        ]}
                        tag={TextDomTag.div}
                        domAttrs={{
                            dangerouslySetInnerHTML: {
                                __html: desc,
                            },
                        }}
                    />
                )}
                {showButton && button && (
                    <Button
                        styles={{
                            ...(styles?.button ?? ({} as ButtonStyles)),
                            root: [
                                defaultStyles.buttonContainer,
                                styles?.button?.root,
                            ],
                        }}
                        linkFunctionalityDomAttributes={linkDomAttributes}
                        buttonContent={button}
                        showHoverEffect={showButtonHoverEffect}
                        rootDataAttributes={{
                            'data-after-text': Boolean(desc || title),
                        }}
                    />
                )}
            </Div>
        </SlideContainer>
    );
};

const defaultStyles = {
    mediaContainer: {
        common: {
            backgroundColor: '#eee',
            overflow: 'hidden',
            position: 'relative',
        },
    },
    mediaContainerBg: {
        common: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            top: 0,
            right: 0,
        },
    },
    imageBg: {
        common: {
            width: '100%',
            height: '100%',
        },
    },
    media: {
        common: {
            objectFit: 'cover',
            objectPosition: 'center',
            display: 'block',
            width: '100%',
            height: '100%',
        },
    },
    imgOverlay: {
        common: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
    contentElement: {
        common: {
            margin: 0,
        },
    },
    descOverrides: {
        common: {
            marginBlock: 0,
        },
    },
    buttonContainer: {
        common: {
            minWidth: 150,
            width: 'auto',
            paddingInlineStart: 20,
            paddingInlineEnd: 20,
            margin: 0,
            marginBlockStart: 24,
        },
    },
    contentContainer: {
        common: {
            display: 'flex',
            visibility: 'visible',
        },
    },
    title: {
        common: {
            margin: 0,
            marginBlockEnd: 8,
        },
    },
} satisfies Record<string, ResponsiveStylesStructured>;

export default withErrorBoundary({
    Comp: SlideContent,
    componentName: 'SlideContent',
});
