import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    OptionType,
    ProductsStore,
} from 'client/widget-components/ecom/stores';
import { ProductType } from 'client/widget-components/ecom/stores/types';

interface Props extends Record<string, unknown> {
    product: ProductType;
}

export const ecomObserver = (WrappedComponent: React.ComponentType<any>) =>
    observer(function (props: Props) {
        const [storeProduct] = useState(() =>
            ProductsStore.addProduct(props.product)
        );

        const updateProduct = (optionKey: string, optionValue: OptionType) => {
            storeProduct?.updateVariation(
                optionKey,
                optionValue.value.toString()
            );
        };

        const componentProps = {
            ...props,
            productState: storeProduct,
            productData: storeProduct?.productData,
            updateProduct,
        };

        return <WrappedComponent {...componentProps} />;
    });
